import {createBrowserRouter, RouterProvider} from "react-router-dom";

import RecoverPassword from "../components/RecoverPassword";
import CompleteRegistration from "../components/CompleteRegistration";

 const Router = () => {
    // Define public routes
    const publicRoutes = [
        {
            path: "/recover-password",
            element: <RecoverPassword/>
        },
        {
            path: "/complete-registration",
            element: <CompleteRegistration/>
        }
    ];

    // Define routes accessible only to authenticated users
    const protectedRoutes = []

    const router = createBrowserRouter([
        ...publicRoutes,
        ...protectedRoutes
    ]);

    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router}/>
}

export default Router;