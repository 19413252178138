import CrypPay from "./CrypPay";

class Account {
    completeRecoverPassword = async (password, token) => {
        const response = await CrypPay.put("/Account/CompleteRecoverPassword", {
            token: token,
            password: password
        });

        return response.data.response;
    }

    completeAccountRegistration = async (token) => {
        const response = await CrypPay.put("/Account/CompleteAccountRegistration", {
            token: token
        });

        return response.data.response;
    }
}

export default new Account();