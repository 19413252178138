import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";

import CrypPayLogo from "../images/Icon_CrypPayLarge.png";

import Account from "../api/Account";

function CompleteRegistration() {
    const [searchParams] = useSearchParams();
    const [token] = useState(searchParams.get("token"));

    const [message, setMessage] = useState({
        style: "",
        content: ""
    });

    useEffect(() => {
        completeRegistration();
    }, []);

    const completeRegistration = async () => {
        try {
            await Account.completeAccountRegistration(token);

            setMessage({
                style: "success",
                content: "Tu cuenta ha sido activada"
            });
        } catch (e) {
            setMessage({
                style: "error",
                content: "Algo salio mal, intentalo nuevamente"
            });
        }
    }

    return (
        <div className={"container"}>
            <img src={CrypPayLogo} alt={""}/>
            <span className={message.style}>{message.content}</span>
        </div>
    );
}

export default CompleteRegistration;