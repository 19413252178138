import "./Style.css";

import {useSearchParams} from "react-router-dom";
import {useState} from "react";

import CrypPayLogo from "../images/Icon_CrypPayLarge.png";

import Account from "../api/Account";

function RecoverPassword() {
    const [searchParams] = useSearchParams();
    const [token] = useState(searchParams.get("token"));

    const [visibility, setVisibility] = useState("visibility");
    const [passwordType, setPasswordType] = useState("password");
    const [password, setPassword] = useState("");

    const [visibilityConfirm, setVisibilityConfirm] = useState("visibility");
    const [passwordTypeConfirm, setPasswordTypeConfirm] = useState("password");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [message, setMessage] = useState({
        style: "",
        content: ""
    });

    const onPasswordChange = (event) => {
        setMessage({
            style: "",
            content: ""
        });
        setPassword(event.target.value);
    }

    const onPasswordConfirmChange = (event) => {
        setMessage({
            style: "",
            content: ""
        });
        setPasswordConfirm(event.target.value);
    }

    const togglePassword = (visibility, handleVisibility, handleType) => {
        if (visibility === "visibility") {
            handleVisibility("visibility_off");
            handleType("text");
        } else {
            handleVisibility("visibility");
            handleType("password");
        }
    }

    const onSubmit = async (event) => {
        event.preventDefault()

        // Validate passwords match
        if (password !== passwordConfirm) {
            setMessage({
                style: "error",
                content: "Las contraseñas deben coincidir"
            });
            return;
        }

        try {
            await Account.completeRecoverPassword(password, token);

            setMessage({
                style: "success",
                content: "Contraseña actualizada"
            })
        } catch (e) {
            setMessage({
                style: "error",
                content: "Algo salio mal, intentalo nuevamente"
            });
        }
    }

    return (
        <div className={"container"}>
            <img src={CrypPayLogo} alt={""}/>
            <form className={"change-password"} onSubmit={onSubmit}>
                <label className={"title"}>Ingresa una nueva contraseña para tu cuenta</label>
                <div className={"input-field"}>
                    <input id={"password-1"} type={passwordType} value={password} onChange={onPasswordChange}/>
                    <label htmlFor={"password-1"}>Contraseña</label>
                    <i className={"material-icons show-password"}
                       onClick={() => togglePassword(visibility, setVisibility, setPasswordType)}>{visibility}</i>
                </div>
                <div className={"input-field"}>
                    <input id={"password-2"} type={passwordTypeConfirm} value={passwordConfirm}
                           onChange={onPasswordConfirmChange}/>
                    <label htmlFor={"password-2"}>Confirma la contraseña</label>
                    <i className={"material-icons show-password"}
                       onClick={() => togglePassword(visibilityConfirm, setVisibilityConfirm, setPasswordTypeConfirm)}>{visibilityConfirm}</i>
                </div>
                {message.content !== "" && <span className={message.style}>{message.content}</span>}
                <button className={"btn"}>Guardar</button>
            </form>
        </div>
    );
}

export default RecoverPassword;